import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import SEO from "../../components/seo";
import { NightLifeCardFlex } from "../../components/nightlifeSearch";
import App from "../app";
import getLang from "../../components/scripts/getLang";
import { NightLifeQuery } from '../../components/scripts/graphQlQuerys/nightLifeQuery' 
import Taggar from '../../components/taggar'
import Searchbar from "../../components/searchbar";
import Hero from '../../components/hero'
import * as Global from "../../css/globalStyle";

const NightLife = () => {
  let [english] = useGlobal("English");
  const pageType = "Nattliv Alla"
  let HeroData = getLang(english, NightLifeQuery());
  let { seoTitel ,seoDescription, Synligataggar, introText } = HeroData[0].node;
  const [SearchKeyword, setSearchKeyword] = useState(' ');

  useEffect(() => {
    const hashtag = window.location.hash;
    if (hashtag) {
      const currentURL = window.location.href;
      const newURL = currentURL.split("#")[0];
      window.history.replaceState("", "NewYear", newURL);
    }
  });

  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <Hero introText={introText} heroTema={null} data={HeroData} img={'img1'} />
      <Searchbar english={english} setSearchKeyword={setSearchKeyword} SearchKeyword={SearchKeyword} />
      <Taggar mainPage="nightlife" subPage="Alla" data={Synligataggar}/>
      <NightLifeCardFlex pageType={pageType} english={english} SearchKeyword={SearchKeyword} />
    </App>
  );
};

export default NightLife;
