import React from "react";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";
import * as Styled from "../css/CardFlexStyle";
import getLang from "../components/scripts/getLang";
import CheckForHighlight from "./scripts/checkForHightlight";
import { SortBySeason2 } from "./scripts/SortBySeasons";

import { EnheterQuery } from './scripts/graphQlQuerys/enheterQuery'


export const NightLifeCardFlex = props => {
  let { SearchKeyword, english, pageType } = props;
  if (SearchKeyword === undefined) {
    SearchKeyword = "";
  }

  let regex = new RegExp(SearchKeyword.trim(), "gi");
  let nattliv = getLang(english, EnheterQuery())
  nattliv = SortBySeason2(nattliv)
  nattliv = CheckForHighlight(pageType, nattliv)
    .filter(edges => {
      return edges.node.sida.includes("Nattliv");
    })
    .filter(nattliv => {
      let newString = nattliv.node.skord.toString();

      return newString.match(regex);
    })
    .map((item, index) => {
      let orderOne = "";
      if (item.node.highlight) {
        orderOne = "orderOne";
      }
      return (
        <Link
          className={"textDecorationNone" + orderOne}
          key={index}
          to={`/nightlife/${item.node.pekaMotSlug ? item.node.pekaMotSlug : item.node.slug}`}
        >
          <BackgroundImage
            Tag="div"
            className={"cardFlex"}
            fluid={item.node.huvudBild.fluid}
            key={index}
          >
            <Styled.FlexH1 seasonClosed={item.node.seasonClosed}>
              <Styled.H2>{item.node.namn.toUpperCase()}</Styled.H2>
            </Styled.FlexH1>
          </BackgroundImage>
        </Link>
      );
    });

  return (
    <Styled.CenterDiv>
      <Styled.FlexContainer>{nattliv}</Styled.FlexContainer>
    </Styled.CenterDiv>
  );
};


